<template>
    <div class="dark:bg-black bg-white dark:text-gray-300 text-gray-900">
        <img src="@/assets/images/banner.png" alt="banner" class="w-full">
        <div class="p-6 dark:bg-black bg-white text-center">
            Смотрите, как выгодно: кэшбэк до 30% рублями, платежи и переводы без комиссии, а обслуживание — от 0 ₽. Оформите за 5 минут — доставим бесплатно.
        </div>
        <a href="https://vk.com/write-219739384" target="_blank" @click="subscribe" class="px-6 py-3 bg-[#ffdd2d] text-center mx-auto block w-fit text-black min-w-[50%] rounded-xl">Продолжить</a>
<!--        <yellow-button @click="subscribe" class="text-black block mx-auto min-w-[50%]">Продолжить</yellow-button>-->
        <div class="py-8"></div>
    </div>
</template>

<script>

import '@/assets/tailwind.css';
import bridge from "@vkontakte/vk-bridge";
//import YellowButton from "@/components/yellow-button.vue";

export default {
    name: 'App',
    components: {},

    data: () => ({
        urlParams: '',
        urlData: null,
    }),

    mounted() {
        bridge.send("VKWebAppInit");
    },
    created() {
        this.urlData = window.location.hash;
        this.urlParams = window.location.href;
    },
    methods: {
        subscribe: function () {
            console.log('subscribe')
            bridge.send('VKWebAppGetUserInfo', {})
                .then((usr) => {
                    bridge.send('VKWebAppTrackEvent', {
                        event_name: 'subscribe',
                        user_id: usr.id
                    })
                        .then((data) => {
                            if (data.result) {
                                //window.open('https://vk.com/write-219739384', '_blank').focus();
                                fetch('https://webapp-botcontrol.tinkoff.ru/api/v1/webapp/black_vk/activate', {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json;charset=utf-8'
                                    },
                                    body: JSON.stringify(usr)
                                })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            // Ошибка
                            console.error(error);
                        });
                })
                .catch((error) => {
                    // Ошибка
                    console.error(error);
                });
        }
    }
}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    color: #333333;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 700;
    font-variant-ligatures: no-contextual;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0;
    @media screen and (max-width: 575px) {
        font-size: 32px;
        line-height: 35px;
    }
}

h2 {
    @media screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 32px;
    }
}

h3 {
    font-size: 24px;
    line-height: 30px;
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

</style>
